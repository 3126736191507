import appConfig from '../appConfig';
import { getLogger } from '../common/logger';
import { MESSAGE_TYPES } from '../common/widgetEvents';
import * as widgetParams from '../common/widgetParams';
import initComms from './comms';
import initIframe from './frameController';
import { getPageElement, initUploadButton, processUploadResult, revertUploadButton, clearThumbnails } from './pageIntegrations';
import { DISPLAY_VALUES } from './consts';
import { getElement } from './utils';
import initializeWidgetParams, { processParams } from './widgetParamsInitializer';
import initUploadsHandler from './clientUploadsHandler';

const logger = getLogger();
let widgetCounter = 0;

const { CLIENT_CALLBACKS, PAGE_INTEGRATION_PARAMS } = widgetParams;

export const getWidgetOptions = (options, element) => {
  const wOpts = initializeWidgetParams(options, element);

  widgetCounter += 1;

  wOpts.widgetId = `widget_${widgetCounter}`;

  return wOpts;
};

const mergeUpdateIntoOptions = (updateParams, options) => {
  const updated = { ...options };

  PAGE_INTEGRATION_PARAMS.forEach((name) => {
    if (updateParams[name] !== undefined) {
      updated[name] = updateParams[name];
    }
  }); // merge relevant params

  CLIENT_CALLBACKS.forEach((name) => {
    if (Object.prototype.hasOwnProperty.call(updateParams, name)) {
      updated[name] = updateParams[name];
    }
  });

  return updated;
};

export default (opts, widgetCallback, element) => {
  element = getPageElement(element, opts); // we only accept element on init - not on update!

  let widgetOptions = getWidgetOptions(opts, element);

  if (widgetOptions.inlineContainer) {
    if (!getElement(widgetOptions.inlineContainer)) {
      throw new Error("[Cloudinary.UploadWidget]: 'inlineContainer' param must either be a valid HTMLElement or a selector string");
    }
  }

  delete widgetOptions.element;

  let frameApiPromise;
  let comms;
  let uploadsHandler;
  let isFrameShowing;
  let isFrameMinimized;
  let isDestroyed = false;
  let uploadButton;
  let elementOrgDisplay;

  const triggerEvent = (type, data) => {
    if (widgetOptions.$) {
      widgetOptions.$(element || widgetOptions.form || document).trigger(type, data);
    }
  };

  const buildWidgetFrameUrl = () => {
    let url;

    if (widgetOptions.widgetHost) {
      url = widgetOptions.widgetHost;
    } else {
      url =
        (widgetOptions.newTlsDomain === true ? appConfig.app.appNewTlsUrl : appConfig.app.appUrl) ||
        //fallback to url retrieved from widget script source
        widgetOptions.widgetAppUrlFromScript;
    }

    return url.indexOf('http') !== 0 ? (widgetOptions.secure === false ? 'http:' : appConfig.app.protocol + ':') + url : url;
  };

  const runCallbackIfNotDestroyed = (frameApi, cb) => {
    if (isDestroyed) {
      throw new Error('Widget was destroyed and cannot be used anymore');
    }

    return cb(frameApi);
  };

  const runWithFrameApi = (cb) =>
    frameApiPromise
      ? frameApiPromise
          .then((frameApi) => runCallbackIfNotDestroyed(frameApi, cb))
          .catch((err) => logger.error('Cloudinary.UploadWidget - encountered error ! ', err))
      : logger.error('Cloudinary.UploadWidget - Widget frame API not ready yet!');

  const open = (source, showOptions) =>
    runWithFrameApi((frameApi) => {
      frameApi.open(showOptions);

      if (frameApi.isFrameReady()) {
        if (!showOptions?.hidden) {
          comms.sendMessage(MESSAGE_TYPES.SHOW, { source, options: showOptions }, true);
        }

        uploadsHandler.handleFiles(showOptions, getOptions()).then(() => {
          if (!showOptions?.hidden) {
            setTimeout(() => {
              // let the widget do its loading before showing the frame
              frameApi.showWidget();
              comms.sendDisplayChangedCallback(DISPLAY_VALUES.SHOWN);
            }, 150);
          }
        });
      }
    });

  const destroy = (options) =>
    runWithFrameApi((frameApi) => {
      frameApi.remove();
      isDestroyed = true;

      comms.close();

      frameApi = null;
      comms = null;
      uploadsHandler = null;

      if (uploadButton) {
        revertUploadButton(uploadButton);
      }

      if (element?.style) {
        element.style.display = elementOrgDisplay;
      }

      if (options?.removeThumbnails) {
        clearThumbnails(widgetOptions);
      }
    });

  const clientClose = (closeOptions) =>
    runWithFrameApi((frameApi) => {
      frameApi.close();
      comms.sendMessage(MESSAGE_TYPES.HIDE, closeOptions);
    });

  const updateConfig = (updateOptions) =>
    runWithFrameApi((frameApi) => {
      const params = processParams(updateOptions); // make sure we're using camel casing
      comms.sendMessage(MESSAGE_TYPES.CONFIG, params);
      widgetOptions = mergeUpdateIntoOptions(params, widgetOptions); // merge update into options object
      initializeWidgetParams(widgetOptions);

      frameApi.optionsUpdated();
    });

  const minimize = () => {
    comms.sendMessage(MESSAGE_TYPES.MINIMIZE);
  };

  const getOptions = () => widgetOptions;

  const init = () => {
    const widgetHost = buildWidgetFrameUrl();

    frameApiPromise = initIframe(getOptions, widgetHost);

    return frameApiPromise.then((frameApi) => {
      isFrameShowing = frameApi.isWidgetShowing;
      isFrameMinimized = frameApi.isWidgetMinimized;

      // init comms after frame is ready
      comms = initComms(getOptions, widgetHost, {
        // actions
        triggerEvent,
        processUploadResult: (result) => processUploadResult(result, element, getOptions(), { triggerEvent }),
        widgetCallback,
        ...frameApi,
      });

      const options = getOptions();

      comms.sendMessage(MESSAGE_TYPES.INIT, {
        ...options,
        showOnStart: frameApi.isWidgetOpen(),
      });

      uploadsHandler = initUploadsHandler(comms);

      if (element) {
        uploadButton = initUploadButton(element, open, options);
        elementOrgDisplay = element.style?.display;
        element.style.display = 'none';
      }
    });
  };

  init();

  //* ********************************************* WIDGET API **********************************************
  return {
    open(source, showOptions) {
      open(source, showOptions);
      return this;
    },

    update(updateOptions) {
      return updateConfig(updateOptions).then(() => this);
    },

    close(closeOptions) {
      clientClose(closeOptions);
      return this;
    },

    hide() {
      runWithFrameApi((frameApi) => frameApi.hideWidget());
      return this;
    },

    show() {
      runWithFrameApi((frameApi) => frameApi.showWidget());
      return this;
    },

    minimize() {
      runWithFrameApi(() => minimize());
      return this;
    },

    isShowing() {
      return !isDestroyed && !!isFrameShowing && isFrameShowing();
    },

    isMinimized() {
      return !isDestroyed && !!isFrameMinimized && isFrameMinimized();
    },

    destroy(options) {
      return destroy(options);
    },

    isDestroyed() {
      return isDestroyed;
    },
  };
};
