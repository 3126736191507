import {
  getAppEnv,
  getEnvTypeForNumberedEnvs,
  getLocalStorageEnv,
  replaceConfigEnvTemplate,
  getCookie,
  getConfCookieName,
  getAppConfig,
  getUrlConf,
  getNodeEnv,
  getRawConfig,
  get,
  getUrlEnv,
} from './helpers';
import { DEFAULT_ENV, ENV_COOKIE_NAME, DEFAULT_CONF } from './consts';

const cookieEnv = getCookie(ENV_COOKIE_NAME);
const cookieConf = getCookie(getConfCookieName());

const localStorageEnv = getLocalStorageEnv();
const appEnv = getAppEnv();
const appConfig = getAppConfig();
const nodeEnv = getNodeEnv();
let overrideDefaultEnv;

let isGetEnvNameCalled = false;

const getDefaultEnvName = () => appEnv || overrideDefaultEnv || getUrlEnv() || DEFAULT_ENV;

const getCustomEnvName = () => cookieEnv || localStorageEnv;

export const getConfigName = () => cookieConf || appConfig || getUrlConf() || nodeEnv || DEFAULT_CONF;

export const getEnvName = () => {
  isGetEnvNameCalled = true;

  return getCustomEnvName() || getDefaultEnvName();
};

const getCurrentConfig = (config, configName) => {
  let currentConfig = config[configName];

  if (currentConfig) {
    return currentConfig;
  }

  if (configName !== DEFAULT_CONF) {
    if (process.env.NODE_ENV !== 'test') {
      // console.warn('env-config', `There is no config with name "${configName}", using "${DEFAULT_CONF}" as a fallback`);
    }
    currentConfig = config[DEFAULT_CONF];
  }

  if (!currentConfig) {
    if (process.env.NODE_ENV !== 'test') {
      // console.warn('env-config', `There is no config with name "${DEFAULT_CONF}", using empty config as a fallback`);
    }
    currentConfig = {};
  }

  return currentConfig;
};

/**
 * envName is the backend environment name, for example eod4cldcat
 * configName is the section that should be selected from the raw config, for example: development
 *
 * Giving the following params this will be the result:
 *  rawConfig = {development: {url: '<%ENV%>cloudinary.com/dev'}, production: {url: '<%ENV%>cloudinary.com/prod'} }
 *  envName = 'eod4cldcat'
 *  configName = 'development'
 *
 * result: {url: 'eod4cldcat.cloudinary.com/dev'}
 **/
export const getDecoratedLibConfig = (rawConfig, envName = getEnvName(), configName = getConfigName()) => {
  const currentConfig = getCurrentConfig(rawConfig, configName);
  const decoratedConfig = replaceConfigEnvTemplate(envName, currentConfig);

  return (path) => {
    return path ? get(decoratedConfig, path) : decoratedConfig;
  };
};

export const getConfEnvName = () => {
  const envName = getEnvName();

  return getEnvTypeForNumberedEnvs(envName) || envName;
};

const getDecoratedAppConfig = getDecoratedLibConfig(getRawConfig());

export const setDefaultEnv = (defaultEnv) => {
  if (isGetEnvNameCalled) {
    console.error(
      'env-config',
      'You set the default environment after the environment was already called, your application may be in an inconsistent state!'
    );
  }
  if (appEnv) {
    // console.warn('env-config', 'APP_ENV was set, default environment will be ignored');
  }
  overrideDefaultEnv = defaultEnv;
};

export default getDecoratedAppConfig;
