export const ENVS = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  STAGING: 'staging',
  NIGHTLY: 'nightly',
};

export const ENV_COOKIE_NAME = 'cld-env';
export const CONF_COOKIE_NAME = 'cld-conf';

export const STORAGE_ENV_KEY_NAME = 'env';

export const DEFAULT_ENV = 'production';
export const DEFAULT_CONF = 'production';
