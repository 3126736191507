// TODO: Fix
 
import { getIframeUrlFromScriptUrl } from '@cld/widget-script-url';

//making call first thing as its safest (cannot get url from script inside functions)
const widgetAppUrlFromScript = getIframeUrlFromScriptUrl();

const getAppUrl = () => {
  //TODO: changing subdomain and removing v2.0 should be temporary until devops add CDN redirect
  const url = new URL(widgetAppUrlFromScript);
  url.pathname =
    url.pathname
      // need to remove "/global" and /v2.0 parts for UW app
      .replace('/v2.0', '')
      .replace('/global', '') +
    //need to add index.html for UW app
    'index.html';
  url.hostname = url.hostname.replace(/^widget/, 'upload-widget');

  return url.toString();
};

export default getAppUrl;
