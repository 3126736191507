import { ENVS } from '../consts';
import { getEnvNameAfterDash, getCloudinarySubDomain } from './getEnvFromUrl';

const envOnDemandSubdomainPrefix = 'eod4cld';

const getConfNamesForValue = (value) => {
  //if the widget is running on env-on-demand subdomain, we should go to staging
  if (value.includes(envOnDemandSubdomainPrefix)) {
    return ENVS.STAGING;
  }

  // for other envs we should go according to the name present in the subDomain
  return getEnvNameAfterDash(value);
};

/**
 * figures out the conf name based on the URL currently loaded in
 * examples:
 *  https://www.cloudinary.com/ -> undefined
 *  https://staging.cloudinary.com -> staging
 *  https://something-staging14.cloudinary.com -> staging14
 *  https://kuku.cloudinary.com -> kuku
 *  https://console-staging2.cloudinary.com -> staging2
 *  https://console-eod4cldxxx.cloudinary.com -> eod4cldxxx
 *  https://console.cloudinary.com -> console
 */
export const getConfFromUrl = () => {
  const subDomain = getCloudinarySubDomain();

  if (subDomain) {
    return getConfNamesForValue(subDomain);
  }
};

export default getConfFromUrl;
