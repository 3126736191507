import { getLogger } from '../common/logger';
import { WIDGET_VIEW_TYPES } from '../common/widgetConsts';
import { MESSAGE_TYPES, EXT_EVENTS } from '../common/widgetEvents';
import { isString, isFunction } from './utils';
import { DISPLAY_VALUES } from './consts';

const logger = getLogger();

export default (getOptions, widgetHost, actions) => {
  const pmOrigin = new URL(widgetHost).origin;

  logger.log(`[all.comms]: creating comms channel for domain =  ${widgetHost}`);

  const sendCallback = (data, err) => {
    if (actions.widgetCallback) {
      actions.widgetCallback(err, data);
    }
  };

  const serializeMessage = (type, data) => JSON.stringify({ type, data });

  const deserializeMessage = (str) => {
    let data;

    try {
      if (isString(str)) {
        data = JSON.parse(str);
      }
    } catch (ex) {
      logger.log('[all.comms]: failed to deserialize message: ', str);
    }

    return data;
  };

  const sendMessage = (type, data, dontSerialize = false) => {
    const pmData = dontSerialize ? { type, data } : serializeMessage(type, data);

    actions.postMessage(pmData);
  };

  const sendDisplayChangedCallback = (status) => {
    sendCallback({
      info: status,
      event: EXT_EVENTS.DISPLAY_CHANGED,
      uw_event: true,
      // TODO: keep data here because the console relies on this structure for now (should fix in console and then remove here)
      data: {
        event: EXT_EVENTS.DISPLAY_CHANGED,
        info: status,
      },
    });
  };

  const eventHandlers = {
    [EXT_EVENTS.WIDGET_VIEW_TYPE_CHANGE]: (data) => {
      actions.handleWidgetViewTypeChange(data.info);

      const status = data.info.type === WIDGET_VIEW_TYPES.MINI ? WIDGET_VIEW_TYPES.MINI : WIDGET_VIEW_TYPES.EXPANDED;

      sendDisplayChangedCallback(status);
    },

    [EXT_EVENTS.FILE_FINISH]: (data) => {
      logger.log('[all.comms]: received uploaded file data - ', data);

      if (data.info.failed) {
        sendCallback(data.info, {
          status: data.info.status,
          statusText: data.info.statusText,
        });

        actions.triggerEvent('cloudinarywidgetfileuploadfail', [data.info]);
      } else {
        const { uploadInfo } = data.info;
        const result = {
          event: 'success',
          info: {
            id: data.info.id,
            batchId: data.info.batchId,
            ...uploadInfo,
          },
        };

        actions.processUploadResult(uploadInfo);
        sendCallback(result);
        actions.triggerEvent('cloudinarywidgetfileuploadsuccess', result);
      }
    },
  };

  const messageHandlers = {
    [MESSAGE_TYPES.EVENT]: (data, options) => {
      if (data.event && eventHandlers[data.event]) {
        // check if we have a handler for this type of event
        eventHandlers[data.event](data, options);
      } else {
        sendCallback({
          // bubble out the event to the client
          // the real event's data
          info: data.info,
          // the event name
          event: data.event,
          // prop to distinguish from 1.0 version events
          uw_event: true,
          // TODO: keep data here because the console relies on this structure for now (should fix in console and then remove here)
          data,
        });
      }
    },

    [MESSAGE_TYPES.HIDE]: () => {
      actions.hideWidget();

      // use of "close" as event name is confusing but its here for back-compat
      const result = { event: 'close', info: { message: 'user closed the widget' } };
      sendCallback(result);
      actions.triggerEvent('cloudinarywidgetclosed', result);

      sendDisplayChangedCallback(DISPLAY_VALUES.HIDDEN);
    },

    [MESSAGE_TYPES.PREPARE]: (data, options) => {
      const sendPrepared = (data) => sendMessage(MESSAGE_TYPES.PREPARE, data);

      const confPrepareFn = options.prepareUploadParams || options.uploadSignature; // keeping uploadSignature for back-compat

      if (isFunction(confPrepareFn)) {
        confPrepareFn(
          (data) => {
            logger.log('[all.comms]: received prepared data from client: ', data);

            const prepared = []
              .concat(data) // make sure were sending back an array of objects
              .map((item) => (isString(item) ? { signature: item } : item));

            sendPrepared(prepared);
          },
          data.request,
          data.files
        );
      } else if (isString(options.uploadSignature)) {
        sendPrepared([{ signature: options.uploadSignature }]);
      }
    },

    [MESSAGE_TYPES.PRE_BATCH]: (data, options) => {
      if (isFunction(options.preBatch)) {
        options.preBatch((clientData) => {
          logger.log('[all.comms]: received pre-batch data from client: ', clientData);
          sendMessage(MESSAGE_TYPES.PRE_BATCH, clientData);
        }, data.request);
      } else {
        throw new Error('UploadWidget - preBatch handler not found!');
      }
    },

    [MESSAGE_TYPES.TAGS]: (data, options) => {
      options.getTags((tags) => {
        logger.log('[all.comms]: received tags from client: ', tags);
        sendMessage(MESSAGE_TYPES.TAGS, { tags });
      }, data.prefix);
    },

    [MESSAGE_TYPES.UPLOAD_PRESETS]: (data, options) => {
      if (isFunction(options.getUploadPresets)) {
        options.getUploadPresets((uploadPresets) => {
          logger.log('[all.comms]: received uploadPresets from client: ', uploadPresets);
          sendMessage(MESSAGE_TYPES.UPLOAD_PRESETS, { uploadPresets });
        });
      } else {
        sendMessage(MESSAGE_TYPES.UPLOAD_PRESETS, { uploadPresets: [] });
      }
    },

    [MESSAGE_TYPES.METADATA_SCHEMA]: (data, options) => {
      options.getMetadataSchema((clientData) => {
        logger.log('[all.comms]: received metadata schema from client: ', clientData);
        sendMessage(MESSAGE_TYPES.METADATA_SCHEMA, clientData);
      }, data);
    },
  };

  function receiveMessage(event) {
    const options = getOptions();

    if (event.origin === pmOrigin) {
      const data = deserializeMessage(event.data);
      let handled = false;

      if (data && data.widgetId) {
        if (data.widgetId === options.widgetId) {
          // only listen to messages from same instance
          logger.log(`[all.comms]: received message from widget: ${options.widgetId}`, data);

          if (messageHandlers[data.type]) {
            handled = true;
            messageHandlers[data.type](data, options);
          }
        }
      }

      if (!handled) {
        logger.log('[all.comms]: received invalid message, invalid widget ID or invalid type! ', event.data);
      }
    }
  }

  window.addEventListener('message', receiveMessage);

  const close = () => {
    window.removeEventListener('message', receiveMessage);
  };

  return {
    sendMessage,
    sendDisplayChangedCallback,
    close,
  };
};
