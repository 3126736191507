// TODO: method copied from packages/dev/webpack-config/lib/helpers.js -
// we dont currently have a good place to share utils between build-time and run-time code. Need to create a package like that
import { isNil } from 'lodash';

const replaceWithEnvVars = (config, vars) =>
  JSON.parse(
    JSON.stringify(config)
      .split(/<%(.+?)%>/)
      //replace placeholder with provided value, if not provided, leave placeholder in place
      .map((val, idx) => (idx % 2 === 0 ? val : isNil(vars[val]) ? `<%${val}%>` : vars[val]))
      .join('')
  );

export default replaceWithEnvVars;
