export const EXT_EVENTS = {
  FILE_FINISH: 'upload-finish',
  FILE_ADDED: 'upload-added',
  WIDGET_VIEW_TYPE_CHANGE: 'widget-view-change',
  SOURCE_CHANGED: 'source-changed',
  TAGS: 'tags',
  PUBLICID: 'publicid',
  QUEUES_START: 'queues-start',
  QUEUES_END: 'queues-end',
  SHOW_COMPLETED: 'show-completed',
  ABORT: 'abort',
  RETRY: 'retry',
  BATCH_CANCELED: 'batch-canceled',
  DISPLAY_CHANGED: 'display-changed',
};

export const MESSAGE_TYPES = {
  INIT: 'uw_init', // initialize the widget
  MINIMIZE: 'uw_mini', // minimize widget to mini queue
  CONFIG: 'uw_config', // update widget configuration
  PREPARE: 'uw_prepare', // prepare upload params
  PRE_BATCH: 'uw_prebatch', // callback before batch is added
  EVENT: 'uw_event', // widget life-time events
  SHOW: 'uw_show', // show the widget (after it was hidden)
  HIDE: 'uw_hide', // hide the widget
  TAGS: 'uw_tags', // retrieve tags callback
  UPLOAD_PRESETS: 'uw_upload_presets', // retrieve tags callback
  FILE: 'uw_file', // upload file
  CLIENT_BATCH: 'uw_clientbatch', // batch from client starting
  METADATA_SCHEMA: 'uw_metadata_schema', //callback to get metadata schema
};
