import { getIsFetchSupported } from './utils';

const setXhrHeaders = (xhr, headers) => {
  if (headers) {
    const flowHeaders = headers; // flow is stupid! :(
    Object.keys(flowHeaders).forEach((name) => xhr.setRequestHeader(name, flowHeaders[name]));
  }
};

const readResponse = (isFetch, options, xhr) => {
  const resType = options.responseType;

  const setReadResponse = (res) => {
    // for fetch Response set the read result on the returned object
    xhr.response = res;
    return xhr;
  };

  return !options.dontRead && isFetch && xhr.ok
    ? resType && xhr[resType]
      ? xhr[resType]().then(setReadResponse) // read using requested type (blob, json, text)
      : xhr.json().then(setReadResponse) // default to json type
    : xhr;
};

const simpleFetch = (url, method = 'GET', data, headers, options = {}) => {
  const body = data && typeof data === 'object' ? JSON.stringify(data) : data;
  const isFetch = getIsFetchSupported();

  const reqPromise = isFetch
    ? self.fetch(url, {
        // fetch is supported
        method,
        body,
        headers: headers ? new Headers(headers) : undefined,
        ...options.fetchOptions,
      })
    : new Promise((resolve, reject) => {
        // fetch not supported - doing xhr instead
        const req = new XMLHttpRequest();

        req.open(method, url);

        if (options.responseType) {
          req.responseType = options.responseType;
        }

        req.onerror = () => reject(req);
        req.ontimeout = () => reject(req);
        req.onload = () => resolve(req);

        setXhrHeaders(req, headers);

        req.send(body);
      });

  return reqPromise.then(readResponse.bind(null, isFetch, options));
};

export { simpleFetch };
