//TODO: remove when IE11 support ends (June 2022?)
import 'current-script-polyfill'; //IE11 polyfill

/**
 * Cannot run inside a callback/promise or any other async function
 * @returns {URL}
 */
export const getCurrentScriptUrl = () => {
  return new URL(document.currentScript.src);
};

/**
 * Cannot run inside a callback/promise or any other async function
 * @returns {string}
 */
export const getIframeUrlFromScriptUrl = () => {
  const url = getCurrentScriptUrl();
  const origPathname = url.pathname;
  url.pathname = origPathname.replace(/[^/]+$/, 'widget/');
  url.search = '';
  return url.toString();
};
