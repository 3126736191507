const { console } = self; // allow uglify
const LEVELS = Object.freeze({
  NONE: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  LOG: 4,
});
const METHODS = ['error', 'warn', 'info', 'log']; // order of items is important!
const RB_METHODS = {
  // https://github.com/rollbar/rollbar.js/blob/16b0d1a1ae8289435ac4e98e7a76e346ddbdcecc/index.d.ts#L36
  debug: ['critical', 'error', 'warn', 'debug', 'log'],
  info: ['critical', 'error', 'warn', 'info'],
  warning: ['critical', 'error', 'warn'],
  error: ['critical', 'error'],
  critical: ['critical'],
};
const SUPPORTED =
  typeof console !== 'undefined' &&
  typeof console.log !== 'undefined' &&
  typeof console.error !== 'undefined' &&
  typeof console.debug !== 'undefined' &&
  typeof console.warn !== 'undefined' &&
  typeof Function.prototype.apply === 'function'; // IE9+

let defaultLevel;
let defaultInstance;

const writeToLog = (logger, method, args) => {
  if (SUPPORTED) {
    // silently ignore if not supported
    const mIndex = METHODS.indexOf(method);
    const loggerLevel = logger.getLevel();

    if (~mIndex && loggerLevel >= mIndex + 1) {
      console[method](...args);
    }

    const RB_ENABLED = window.Rollbar && window.Rollbar.options.enabled;
    if (RB_ENABLED && ~RB_METHODS[window.Rollbar.options.reportLevel]?.indexOf(method)) {
      window.Rollbar[method]?.(...args);
    }

    return logger;
  }
};

const writeGroup = (logger, method, title, fallbackTitle) =>
  console[method] ? (title ? console[method](title) : console[method]()) : logger.log(`----------- ${title || fallbackTitle} ----------- `);

const createLogger = (options) => {
  let _level = options.level;

  const logger = {};

  logger.setLevel = (level) => {
    _level = level;
    return logger;
  };

  logger.getLevel = () => _level || defaultLevel;

  METHODS.forEach((m) => {
    logger[m] = (...args) => writeToLog(logger, m, args);
  });

  logger.groupCollapsed = (title) => writeGroup(logger, 'groupCollapsed', title, 'GROUP START');

  logger.group = (title) => writeGroup(logger, 'group', title, 'GROUP START');

  logger.groupEnd = () => writeGroup(logger, 'groupEnd', null, 'GROUP END');

  logger.devError = (...args) => {
    if (process.env.NODE_ENV !== 'production') {
      logger.error(...args);
    }
  };

  logger.debug = logger.log;

  return logger;
};

const getLogger = (options = {}) => {
  options.level = options.level || LEVELS.NONE;

  const logger = options.newInstance || !defaultInstance ? createLogger(options) : defaultInstance;

  if (!defaultInstance && !options.newInstance) {
    defaultInstance = logger; // first time store the instance for future use
  }

  return logger;
};

const setDefaultLevel = (level) => (defaultLevel = level);

export default getLogger;

export { LEVELS, getLogger, setDefaultLevel };
